//Importes do React
//Importes do Router-Dom
//Importes de Icones
//Importes de CSS
import "../../estilo/candidato_entrevistas_agendadas.css";
//Importes de Paginas

function CandidatoEntrevistasAgendadas() {
  return (
    <div>
      <h2>candidato entrevistas agendadas</h2>
    </div>
  );
}

export default CandidatoEntrevistasAgendadas;
