//Importes do React
//Importes do Router-Dom
//Importes de Icones
//Importes de CSS
import "../../estilo/empresas_configuracao.css";
//Importes de Paginas

function EmpresasConfiguracao() {
  return (
    <div>
      <h1>Configurações Empresa</h1>
    </div>
  );
}

export default EmpresasConfiguracao;
