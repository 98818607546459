//Importes do React
//Importes do Router-Dom
//Importes de Icones
//Importes de CSS
import "../../estilo/empresas_ver_vagas.css";
//Importes de Paginas

function EmpresasVerVagas() {
  return (
    <div>
      <h1>Vagas publicadas Empresa</h1>
    </div>
  );
}

export default EmpresasVerVagas;
