//Importes do React
//Importes do Router-Dom
//Importes de Icones
//Importes de CSS
import "../../estilo/candidato_minhas_vagas.css";
//Importes de Paginas

function CandidatoVagas() {
  return (
    <div>
      <h1>Vagas Cadastradas Candidato</h1>
    </div>
  );
}

export default CandidatoVagas;
